.margin {
  position: relative;
  min-width: 100%;
  overflow: hidden;
  min-height: 700px;
  height: 80vh;
  max-height: 90vh; 
  min-width: 600px;
}

.main{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 80vh;
  min-width: 900px;
  min-height: 700px;
  overflow: hidden;
}

.video {
  width: 200%;  
  min-width: 600px;
  height: 120vh;
  min-height: 700px;
  object-fit: cover;
  position: relative;
  top: -12vh;
  left: -50%
}

.content {
  background-color:rgba(2, 1, 1, 0.848);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
.overlay {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%; 
  height: 100%;
  background-color: rgba(0, 0, 0, 0.948);
}




