.rigth-column {
  position: relative;
  left: 50%;
  width: 50%;
  height: 100%;
  /* background: white; */
  max-height: 100%; 
}

.axonImg{
  
  position: relative;
  top: 10%;
  left: 5%;
  max-width: 80%;
  max-height: 80%; 
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 1);
}

