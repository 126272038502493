.invertButton {
  border-radius: 50%;
}
.buyForm {
  padding-bottom: 10px;
}

.buyForm > p {
  margin: 5px;
  padding: 1px;
}
.buyForm > .p-buyForm{
  margin-bottom: 25px;
}

.inputForm{
  min-width: 90%;
  min-height: 60px;
  max-height: 70px;  
  padding: 15px;
  border-radius: 4px;
  font-size: 28px;
  margin-bottom: 20px;
  border: none;
  border-radius: 10px;
  background-color: rgb(33, 36, 41);
}
.inputForm2{
  min-width: 90%;
  min-height: 60px;
  max-height: 70px;  
  padding: 15px;
  border-radius: 4px;
  font-size: 28px;
  margin-bottom: 20px;
  border: none;
  border-radius: 10px;
  background-color: rgb(27, 30, 34);
}

input, select, textarea{
  color: #fafafa;
  /* margin: 5px; */
}

textarea:focus, input:focus {
  color: #fbfbfb;
}

.buyFormButton{
  /* min-width: 70%; */
  border: none;
  margin-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  min-height: 55px;
  border-radius: 7px;
  font-size: 28px;
  font-weight: 600;
  /* font-family:'Arial'; */
  border: 3px solid transparent;
}
.buyFormButtonPointer{
  /* min-width: 70%; */
  border: none;
  margin-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  min-height: 55px;
  border-radius: 7px;
  font-size: 28px;
  font-weight: 600;
  /* font-family:'Arial'; */
  border: 3px solid transparent;
  cursor:pointer;
}

.InvertButton{
  text-decoration: none;
  background-color: rgba(240, 248, 255, 0);
  border: none;
  cursor:pointer;
}
.h3Amount {
  padding: 0px;
  margin: 2px;
}



@media (max-width: 600px) {
  .inputForm{
    font-size: 20px;  
  }
  .inputForm2{
    font-size: 20px;  
  }
}

/* -webkit-font-smoothing: antialiased;
-webkit-tap-highlight-color: transparent;
font-feature-settings: 'ss01' on,'ss02' on,'cv01' on,'cv03' on;
--reach-dialog: 1;
box-sizing: border-box;
font-family: 'Inter custom',sans-serif;
color: rgb(255, 255, 255);
width: 0px;
position: relative;
font-weight: 500;
outline: none;
border: none;
flex: 1 1 auto;
background-color: rgb(33, 36, 41);
font-size: 28px;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
padding: 0px;
appearance: textfield;
filter: none;
opacity: 1;
transition: opacity 0.2s ease-in-out 0s;
text-align: left; */