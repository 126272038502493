h3 {
  display: hide;
}

.conectButton{
  height: 40px;
  max-height: 75%;
  position: relative;
  border-radius: 20px;
  border: solid;
  right: 1%;
  background-color: rgb(2 , 128, 144);
  min-width: 200px;
  font-size: 22px;
  border: 3px solid transparent;
  color: white;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  box-shadow: 0px 0px 3px 0px rgb(0, 0, 0);
}
.div-button-wallet{
  font-size: 22px;
  margin-right: 15px; 
  cursor: default;
}