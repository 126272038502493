.Navbar {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(35 34 52);
  color: white;
  z-index: 10; 
}

.Navbar > .nav-logo {
  font-weight: 700;
  font-size: 26px;
  margin: 15px;
  color: #ffffff;
  z-index: 10; 
  cursor: default;
}

.Navbar > .nav-items > a {
  color: #ffffff;
  font-size: 22px;
  font-weight: 400;
  text-decoration: none;
  margin: 15px;
  position: relative;
  opacity: 0.9;
  z-index: 10; 
}

.Navbar > .nav-items > a:hover {
  opacity: 1;
  z-index: 10; 
}

.Navbar > .nav-items > a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background: #ffffff;
  transition: all 0.45s;
  z-index: 10; 
}

.Navbar > .nav-items > a:hover::before {
  width: 100%;
}

.Navbar > .nav-toggle {
  display: none;
}

@media (max-width: 800px) {
  .Navbar > .nav-items {
    position: absolute;
    top: 60px;
    display: flex;
    flex-direction: column;
    background-color: rgb(66, 65, 100);
    left: 0;
    width: 25%;
    min-width: 200px;
    height: 100%;
    transform: translateX(-100%);
    transition: all .45s;
    z-index: 2; 
  }
  span.nav-logo.nav-items {
    display: none;
  }
  .nav-toggle{
    overflow: hidden;
    z-index: 1;
  }

  .Navbar > .nav-items > a::before {
      background: transparent;
  }

  .Navbar > .nav-items.open {
      transform: translateX(0);
  }

  .Navbar > .nav-toggle {
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .nav-toggle > .bar {
    position: relative;
    width: 32px;
    height: 2px;
    background: #ffffff;
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle > .bar::before,
  .nav-toggle > .bar::after {
    content: "";
    position: absolute;
    height: 2px;
    background: #ffffff;
    border-radius: 4px;
    transition: all 0.45s ease-in-out;
  }

  .nav-toggle > .bar::before {
    width: 25px;
    transform: translateY(-8px);
    right: 0;
  }

  .nav-toggle > .bar::after {
    width: 32px;
    transform: translateY(8px);
  }

  .nav-toggle.open > .bar {
      transform: translateX(-40px);
      background: transparent;
  }

  .nav-toggle.open > .bar::before {
      width: 32px;
      transform: rotate(45deg) translate(26px, -26px);
  }

  .nav-toggle.open > .bar::after {
    transform: rotate(-45deg) translate(26px, 26px);
  }


}

/* background-color: rgb(35 34 52); */