* {
  box-sizing: border-box;
  
}

body {
  margin:0;
  background-color: rgb(44 45 51);
}

.pageSection{
  width: 100%;
  /* height: 75vh; */
  min-height: 500px;
  /* background: #233d70; */
  display: flex;

  text-align: center;
  /* flex-direction: column; */
  align-items: center; 
  justify-content: center; 
  flex-wrap: wrap;
  align-items: stretch;
}
.division{
  position: absolute;
  width: 100%;
  /* height: 100px; */
  /* background-color: blue; */
  top:73%; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.navBar-App{
  z-index: 10;
}